import React from 'react';
import {
  Accordion,
  Column,
  Container,
  Layout,
  LayoutBlock,
  Hero,
  Title,
  Description,
  Row,
  Section,
  Actions,
} from '@dragonchain-dev/dragon-kit';
import { Layout as PageLayout, SEO, TypeForm, FeatureRow } from '../../components/_index';

import Archive from '../../assets/images/icons/archive.svg';
import Brain from '../../assets/images/icons/brain-thought.svg';
import Eye from '../../assets/images/icons/eye.svg';
import Touch from '../../assets/images/icons/touch.svg';
import CardSecure from '../../assets/images/icons/card-secure.svg';
import Shield from '../../assets/images/icons/shield.svg';

const FinTech = () => {
  return (
    <PageLayout>
      <SEO
        title="Case Study: Anti-Fraud and Transparency in Fintech"
        description="Making regulated financial institutions and exchanges more transparent with Anti-Fraud, Proof, and Transparency systems. "
      />
      <Layout dark background="blue">
        <Hero
          type="code"
          backgroundImage="https://images.dragonchain.com/texture/clouds-blue@2x.jpg"
          video={{
            url: 'https://www.youtube.com/watch?v=tqAyivw6H_4',
            autoPlay: true,
          }}
        >
          <Title color="white">Case Study: Anti-Fraud and Transparency in Fintech</Title>
          <Description color="blue-light">
            <p>
              How Dragonchain delivered a solution for Beaxy in 5 days, providing transparency and anti-fraud
              solutions for a US regulated financial institution and cryptocurrency exchange.
            </p>
            <Actions
              actions={[
                {
                  type: 'link',
                  name: 'See Beaxy Proof Reports',
                  color: 'blue',
                  hover: 'blue-dark',
                  to: 'https://beaxy.proof.report',
                  blank: true,
                },
              ]}
            />
          </Description>
        </Hero>
      </Layout>
      <LayoutBlock>
        <Container>
          <Row gap="small">
            <Column span={12}>
              <Section title="From Concept To Operational System In 5 Days" />
            </Column>
          </Row>
          <FeatureRow
            features={[
              {
                Icon: Archive,
                title: 'Front-running',
                body:
                  'Evidence for both the trader and the exchange that all transactions occurred well in advance',
              },
              {
                Icon: Eye,
                title: 'Transparency',
                body: 'Solved lack of transparency in a young and fast developing industry',
              },
              {
                Icon: Touch,
                title: 'Anti-fraud',
                body: 'Disincentives fraud, and detects fraud in a transparent manner',
              },
              {
                Icon: CardSecure,
                title: 'Proof reports',
                body: 'Proof reports for every transaction are available',
              },
              {
                Icon: Shield,
                title: 'Trades and orders',
                body: 'Making all trades and orders transparent.',
              },
              {
                Icon: Brain,
                title: 'By the numbers',
                body: 'Dragonchain will create over 15 million transparent transactions a day for Beaxy.',
              },
            ]}
          />
          <Row gap="small">
            <Column span={12}>
              <Section title="How it started">
                Dragonchain and Beaxy Exchange started working together in a different area at first. Beaxy is
                a US compliant financial service, constantly exploring new innovations that provide more
                transparency to their users. Dragonchain’s Provably Fair System was the first solution we
                successfully integrated with their systems, allowing Beaxy to run trading contests with a
                provably fair random selection of the winner. Now Beaxy will use this system for all of their
                contests and give-aways.
              </Section>
            </Column>
          </Row>
          <Row gap="small">
            <Column span={12}>
              <Section title="Brainstorm">
                Working with Beaxy and doing the integration with different systems was so simple, that we all
                decided to step it up a notch very quickly. This is where our Anti-Fraud and Transparency
                Systems came into play. Front-running, fraud, and market manipulation are not only a problem
                in traditional finance and exchanges, but also in financial services in the cryptocurrency
                industry. We were able to tackle these difficult challenges by combining our experience and
                technical knowledge.
              </Section>
            </Column>
          </Row>
          <Row gap="small">
            <Column span={12}>
              <Section title="Transparency">
                Dragonchain developers were given access to some of the APIs, and worked closely together with
                Beaxy their developers. In a matter of days the proof reports for every trading pair were
                integrated, making not only every trade (buy/sell) transparent, but also making Beaxy the most
                transparent exchange in the world. The next step is doing the same for all orders placed in
                the order books.
              </Section>
            </Column>
          </Row>
        </Container>
      </LayoutBlock>
      <LayoutBlock separator>
        <Container>
          <Row gap="large">
            <Column span={12}>
              <Accordion title="The stats">
                <Accordion.Item title="Man-hours">5 days, with 2 developers</Accordion.Item>
                <Accordion.Item title="Beaxy Deliverables">API access</Accordion.Item>
                <Accordion.Item title="Dragonchain Deliverables">
                  <ul>
                    <li>Blockchain</li>
                    <li>API</li>
                    <li>Website</li>
                    <li>Hosting</li>
                  </ul>
                </Accordion.Item>
              </Accordion>
            </Column>
          </Row>
          <Row>
            <Column span={6}>
              <Section title="Want to learn more?">
                Integrate anti-fraud and transparency solutions in your financial services. Schedule a call
                with the creators.
              </Section>
            </Column>
            <Column span={6} right middle>
            <Actions
            actions={[
              {
                type: 'link',
                name: 'Contact Us',
                color: 'blue',
                hover: 'blue-dark',
                to: '/contact-us',
              },
            ]}
          />
            </Column>
          </Row>
        </Container>
      </LayoutBlock>
    </PageLayout>
  );
};

export default FinTech;
